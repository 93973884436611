<template>
    <hb-modal v-model="dialog" show-help-link size="large" title="Edit Property" :footer-cancel-option="false" :scrollable="false" @close="closeWindow">
        <template v-slot:subheader>
            Manage and edit Property Settings here.
        </template>
        <template v-slot:content>
            <v-row
                v-show="notification.active"
                class="py-1 pl-3 my-4 mx-6"
                :class="'hb-notification-' + notification.type"
            >
                <v-col cols="10" class="ma-0 mt-2 pa-0">
                    <v-icon
                        v-if="notification.type == 'success'"
                        color="#00848E"
                        size="24"
                        class="mt-n1 ml-0 mr-2"
                    >mdi-check</v-icon>
                    <v-icon
                        v-if="notification.type == 'warning'"
                        color="#FB4C4C"
                        size="24"
                        class="mt-n1 ml-0 mr-2"
                    >mdi-close-circle</v-icon>
                    {{notification.message}}
                </v-col>
                <v-col cols="2" class="ma-0 pa-0 text-right">
                    <hb-btn class="mr-1" icon tooltip="Close" active-state-off :hover-background-color="notification.type == 'warning' ? '#F3CCCC' : '#72d479'" @click="notification.active = false">mdi-close</hb-btn> 
                </v-col>
            </v-row>
            <hb-form label="Property Number" :active.sync="active" :disableSave="doesNeedUpdate" @cancel="cancel" @save="save" editable required>
                <template v-slot:display>
                    {{selected.number}}
                </template>
                <template v-slot:edit>
                    <HbTextField
                        v-model="selected.number"
                        v-validate="'max:45|required'"
                        data-vv-scope="default"
                        data-vv-name="property_number"
                        data-vv-as="Property Number"
                        :error="errors.has('default.property_number')"
                        placeholder="Property Number"
                    ></HbTextField>
                </template>
            </hb-form>
            <hb-form label = "Property Name" :active.sync="active" :disableSave="doesNeedUpdate" @cancel="cancel" @save="save" editable required>
                <template v-slot:display>
                    {{selected.name}}
                </template>
                <template v-slot:edit>
                    <HbTextField
                        v-model="selected.name"
                        v-validate="'max:45|required'"
                        data-vv-scope="default"
                        data-vv-name="property_name"
                        data-vv-as="Property Name"
                        :error="errors.has('default.property_name')"
                        placeholder="Property Name"
                    ></HbTextField>
                </template>
            </hb-form>
            <hb-form label = "Legal Name" :active.sync="active" :disableSave="doesNeedUpdate" @cancel="cancel" @save="save" editable>
                <template v-slot:display>
                    {{selected.legal_name}}
                </template>
                <template v-slot:edit>
                    <HbTextField
                        v-model="selected.legal_name"
                        v-validate="'max:45'"
                        data-vv-scope="default"
                        data-vv-name="legal_name"
                        data-vv-as="Legal Name"
                        :error="errors.has('default.legal_name')"
                        placeholder="Legal Name"
                    ></HbTextField>
                </template>
            </hb-form>
            <hb-form label = "Email" :active.sync="active" :disableSave="doesNeedUpdate" @cancel="cancel" @save="save" editable required>
                <template v-slot:display>
                    {{selected.Emails?.[0]?.email}}
                </template>
                <template v-slot:edit>
                    <HbTextField
                        v-model="selected.Emails[0].email"
                        v-validate="{required: true, max:45, email: true, regex: /^\w+([\.\-\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/ }"
                        data-vv-scope="default"
                        data-vv-name="email"
                        data-vv-as="Email"
                        :error="errors.has('default.email')"
                        placeholder="Email"
                    ></HbTextField>
                </template>
            </hb-form>
            <hb-form label = "Phone" :active.sync="active" :disableSave="doesNeedUpdate" @cancel="cancel" @save="save" editable required>
                <template v-slot:display>
                    {{selected?.Phones?.[0].phone | formatPhone}}
                </template>
                <template v-slot:edit>
                    <v-row>
                        <v-col cols="3" class="pt-0 pb-0 ma-0 pr-3 pl-3">
                            <HbAutocomplete
                                v-model="phoneCode"
                                v-validate="'required'"
                                :items="countryCodesList"
                                :clearable="false"
                                data-vv-scope="default"
                                data-vv-as="Phone Code"
                                :name="'phone_code'"
                                :error="errors.has('default.phone_code')"
                                :error-messages="errors.collect('default.phone_code')"
                                placeholder="Code"
                                @change="checkValidPhone(phoneCode, phoneNumber)"
                                @focusout="checkValidPhone(phoneCode, phoneNumber)"
                            >
                                <template v-slot:selection="data">
                                    +{{data.item}}
                                </template>
                                <template v-slot:item="data">
                                    +{{data.item}}
                                </template>
                            </HbAutocomplete>
                        </v-col>
                        <v-col cols="9" class="pa-0 ma-0">
                            <HbTextField
                                v-model="phoneNumber"
                                v-validate="'required|max:45|numeric'"
                                data-vv-scope="default"
                                data-vv-name="phone"
                                data-vv-as="Phone"
                                :error="(errors.has('default.phone') || invalidPhone)"
                                placeholder="Phone"
                                @input="checkValidPhone(phoneCode, phoneNumber)"
                                @focusout="checkValidPhone(phoneCode, phoneNumber)"
                            >
                            </HbTextField>
                        </v-col>
                    </v-row>
                </template>
            </hb-form>
            <hb-form label = "Address" :active.sync="active" :disableSave="doesNeedUpdate" @cancel="cancel" @save="save" editable required>
                <template v-slot:display>
                    <div>{{selected.Address?.address}}, {{selected.Address?.address2}}</div>
                    <div>{{selected.Address?.city}}, {{selected.Address?.state}} {{selected.Address?.zip}}</div>
                    <div>{{selected.Address?.region.name}}</div>
                    <!-- <div>{{selected.Address?.Suburb?.name}}</div> -->
                    <div>{{selected.Address?.district}}</div>
                    <div>{{selected.Address?.country}}</div>
                </template>
                <template v-slot:edit>
                    <v-row>
                        <v-col class="pt-0 pb-0 ma-0 pr-3">
                            <HbAutocomplete
                                :items="countriesListMixin"
                                v-validate="'required'"
                                v-model="selected.Address.country"
                                placeholder="Country"
                                data-vv-scope="default"
                                data-vv-as="Country"
                                name="country"
                                :error="errors.has('default.country')"
                                @change="setPropertyCountry"
                            >
                            </HbAutocomplete>
                        </v-col>
                    </v-row>
                    <v-row >
                        <v-col class="pt-0 pb-0 ma-0 pr-3">
                            <HbTextField
                                v-model="selected.Address.address"
                                v-validate="'required|max:45'"
                                data-vv-scope="default"
                                data-vv-name="address"
                                data-vv-as="Address"
                                :error="errors.has('default.address')"
                                placeholder="Street"
                            >
                            </HbTextField>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pt-0 pb-0 ma-0 pr-3">
                            <HbTextField
                                v-model="selected.Address.address2"
                                v-validate="'max:45'"
                                data-vv-scope="default"
                                data-vv-name="address2"
                                data-vv-as="Suite / Apt"
                                :error="errors.has('default.address2')"
                                placeholder="Suite / Apt"
                            >
                            </HbTextField>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4" class="pt-0 pb-0 ma-0 pl-3">
                            <HbAutocomplete
                                :items="propertyStateListMixin || []"
                                item-text='name'
                                item-value='value'
                                id="state"
                                placeholder="State"
                                single_line
                                v-model.lazy="selected.Address.state"
                                data-vv-name="state"
                                data-vv-as="State"
                                data-vv-scope="default"
                                :hide-details="!errors.collect('default.state').length"
                                :error-messages="errors.collect('default.state')"
                                @change="setPropertyState"
                            />
                        </v-col>
                        <v-col cols="5" class="pt-0 pb-0 ma-0 pl-3">
                            <HbAutocomplete
                                :items="propertyCityListMixin || []"
                                v-validate="propertyCityListMixin && propertyCityListMixin.length ? 'required' : ''"
                                v-model="selected.Address.city"
                                label="City"
                                id="city"
                                data-vv-name="city"
                                data-vv-as="City"
                                data-vv-scope="default"
                                :hide-details="!errors.collect('default.city').length"
                                :error-messages="errors.collect('default.city')"
                                single-line
                                @keydown.enter="setPropertyCityList($event.target.value, true)"
                            >
                            </HbAutocomplete>
                        </v-col>
                        <v-col cols="3" class="pt-0 pb-0 ma-0 pr-3 pl-3">
                            <HbTextField
                                v-model="selected.Address.zip"
                                v-validate="'required|max:45'"
                                data-vv-scope="default"
                                data-vv-name="zip"
                                data-vv-as="Zip"
                                :error="errors.has('default.zip')"
                                placeholder="Zip"
                            >
                            </HbTextField>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4" class="pt-0 pb-0 ma-0 pr-3 pl-3">
                            <HbAutocomplete   
                                v-model="selected.Address.region" 
                                :items="marketRegionsList"
                                item-text="name"
                                return-object
                                placeholder="Region"
                                @keydown.enter="setMarketRegionsList($event.target.value)"
                            ></HbAutocomplete>
                        </v-col>
                        <!-- <v-col cols="4" class="pt-0 pb-0 ma-0 pl-3">
                            <HbAutocomplete
                                v-model="selected.Address.Suburb"
                                :items="suburbsList"
                                item-text="name"
                                return-object
                                placeholder="Suburb"
                                @keydown.enter="setSuburbList($event.target.value)"
                            >
                            </HbAutocomplete>
                        </v-col> -->
                        <v-col cols="4" class="pt-0 pb-0 ma-0 pl-3">
                            <HbTextField
                                v-model="selected.Address.district"
                                v-validate="'max:45'"
                                data-vv-scope="default"
                                data-vv-name="district"
                                data-vv-as="District"
                                :error="errors.has('default.district')"
                                placeholder="District"
                            >
                            </HbTextField>
                        </v-col>
                    </v-row>
                </template>
            </hb-form>
            <hb-form label = "Google Analytics (GA) ID" :active.sync="active" :disableSave="doesNeedUpdate" @cancel="cancel" @save="save" editable>
                <template v-slot:display>
                    {{selected.google_analytics_id}}
                </template>
                <template v-slot:edit>
                    <HbTextField
                        v-model="selected.google_analytics_id"
                        v-validate="'max:45'"
                        data-vv-scope="default"
                        data-vv-name="google_analytics_id"
                        data-vv-as="Google Analytics(GA) ID"
                        :error="errors.has('default.google_analytics_id')"
                        placeholder="GA ID"
                    >
                    </HbTextField>
                </template>
            </hb-form>
            <hb-form label = "Property Web URL" :active.sync="active" :disableSave="doesNeedUpdate" @cancel="cancel" @save="save" editable>
                <template v-slot:display>
                    {{selected.landing_page}}
                </template>
                <template v-slot:edit>
                    <HbTextField
                        v-model="selected.landing_page"
                        v-validate="{max:300, url: {require_protocol: false}}"
                        data-vv-scope="default"
                        data-vv-name="web_url"
                        data-vv-as="Property Web URL"
                        :error="errors.has('default.web_url')"
                        placeholder="Property Web URL"
                    >
                    </HbTextField>
                </template>
            </hb-form>
            <hb-form label = "Tax / ABN Number" :active.sync="active" :disableSave="doesNeedUpdate" @cancel="cancel" @save="save" editable>
                <template v-slot:display>
                    {{selected.tax_number}}
                </template>
                <template v-slot:edit>
                    <HbTextField
                        v-model="selected.tax_number"
                        v-validate="'max:45'"
                        data-vv-scope="default"
                        data-vv-name="tax_number"
                        data-vv-as="Tax / ABN Number"
                        :error="errors.has('default.tax_number')"
                        placeholder="Tax / ABN Number"
                    >
                    </HbTextField>
                </template>
            </hb-form>
            <hb-form label = "Coordinates" :active.sync="active" :disableSave="doesNeedUpdate" @cancel="cancel" @save="save" editable>
                <template v-slot:display>
                    {{selected.Address.lat ? `${selected.Address.lat},` : ''}} {{selected.Address.lng}}
                </template>
                <template v-slot:edit>
                    <v-row>   
                        <v-col cols="6" class="pt-0 pb-0 ma-0 pl-3 pr-3">
                            <HbTextField
                                v-model="selected.Address.lat"
                                v-validate="'between:-90,90'"
                                data-vv-scope="default"
                                data-vv-name="latitude"
                                data-vv-as="Latitude"
                                :error="errors.has('default.latitude')"
                                placeholder="Latitude"
                            >
                            </HbTextField>
                        </v-col>
                        <v-col cols="6" class="pa-0 ma-0">
                            <HbTextField
                                v-model="selected.Address.lng"
                                v-validate="'between:-180,180'"
                                data-vv-scope="default"
                                data-vv-name="longitude"
                                data-vv-as="Longitude"
                                :error="errors.has('default.longitude')"
                                placeholder="Longitude"
                            >
                            </HbTextField>
                        </v-col>
                    </v-row>
                </template>
            </hb-form>
            <hb-form label = "Surrounding Cities" :active.sync="active" :disableSave="doesNeedUpdate" @cancel="cancel" @save="save" editable>
                <template v-slot:display>
                    {{selected.Suburbs?.length ? selected.Suburbs.map(s => s.name).join(', ') : ''}}
                </template>
                <template v-slot:edit>
                    <hb-combobox   
                        v-model="selected.Suburbs" 
                        :items="suburbsList"
                        item-text="name"
                        return-object
                        label="Surrounding Cities"
                    ></hb-combobox>
                </template>
            </hb-form>
            <hb-form label = "Market Region(s)" :active.sync="active" :disableSave="doesNeedUpdate" @cancel="cancel" @save="save" editable>
                <template v-slot:tooltipBody>
                    <div style="max-width: 300px;">
                        Required to use as criteria for creating property groups. Areas where properties are assigned to support business decisions related to marketing or financial considerations.
                    </div>
                </template>
                <template v-slot:display>
                    {{selected.MarketRegions?.length ? selected.MarketRegions.map(s => s.name).join(', ') : ''}}
                </template>
                <template v-slot:edit>
                    <hb-combobox   
                        v-model="selected.MarketRegions" 
                        :items="marketRegionsList"
                        item-text="name"
                        return-object
                        label="Market Regions"
                    ></hb-combobox>
                </template>
            </hb-form>
            <hb-form label = "Merchandise Delivery" :active.sync="active" :disableSave="doesNeedUpdate" @cancel="cancel" @save="save" editable>
                <template v-slot:display>
                    {{selected.merchandise_delivery == 1 ? "Yes" : "No"}}
                </template>
                <template v-slot:edit>
                    <hb-radio-group v-model="selected.merchandise_delivery" row>
                        <hb-radio label="Yes" :value="1"></hb-radio>
                        <hb-radio label="No" :value="0"></hb-radio>
                    </hb-radio-group>
                </template>
            </hb-form>
            <hb-form label = "Year Opened" :active.sync="active" :disableSave="doesNeedUpdate" @cancel="cancel" @save="save" editable>
                <template v-slot:tooltipBody>
                    <div style="max-width: 300px;">
                        Required to use as criteria for creating property groups. Enter the year the property began operations.
                    </div>
                </template>
                <template v-slot:display>
                    {{selected.year_opened | formatDate}}
                </template>
                <template v-slot:edit>
                    <HbDatePicker
                    v-model="selected.year_opened"
                    @click:clear="selected.year_opened = null"
                    label="MM/DD/YYYY"
                    clear-icon="mdi-close-circle"
                    :solo="false"
                    :single-line="true"
                    data-vv-as="year_opened"
                    :error="(isInvalidYear)"
                    />
                </template>
            </hb-form>
            <hb-form label = "Year Closed" :active.sync="active" :disableSave="doesNeedUpdate" @cancel="cancel" @save="save" editable>
                <template v-slot:display>
                    {{selected.year_closed | formatDate}}
                </template>
                <template v-slot:edit>
                    <HbDatePicker
                    v-model="selected.year_closed"
                    @click:clear="selected.year_closed = null"
                    label="MM/DD/YYYY"
                    clear-icon="mdi-close-circle"
                    :solo="false"
                    :single-line="true"
                    data-vv-as="year_closed"
                    :error="(isInvalidYear)"
                    />
                </template>
            </hb-form>
            <hb-form label="Performance Category" :active.sync="active" :disableSave="doesNeedUpdate" @cancel="cancel" @save="save" editable>
                <template v-slot:tooltipBody>
                    <div style="max-width: 300px;">
                        Required to use as criteria for creating property groups. Options include:<br>
                        - New Property: Newly built or acquired.<br>
                        - Expanding Property: Recently expanded.<br>
                        - Performance-Based: High-performing or performance-challenged properties based on occupancy threshold.<br>
                    </div>
                </template>
                <template v-slot:display>
                    {{performanceCategory}}
                </template>
                <template v-slot:edit>
                    <HbSelect 
                        v-model="selected.performance_category"
                        :items="performanceCategoryList"
                        item-text="name"
                        item-value="label"
                        placeholder="Performance Category"
                    />
                </template>
            </hb-form>
            <hb-form label = "Occupancy Threshold" :active.sync="active" :disableSave="doesNeedUpdate" @cancel="cancel" @save="save" editable>
                <template v-slot:tooltipBody>
                    <div style="max-width: 300px;">
                        Required to use as criteria for creating property groups. If "Performance-Based" is selected under Performance Category, this threshold defines whether a property is high-performing or performance-challenged. The default is 85.
                    </div>
                </template>
                <template v-slot:display>
                    {{selected.occupancy_threshold}}
                </template>
                <template v-slot:edit>
                    <HbSelect 
                        v-model="selected.occupancy_threshold"
                        :items="occupancyThresholds"
                        placeholder="Occupancy Threshold"
                    />
                </template>
            </hb-form>
            <hb-form label="Management Contact" :active.sync="active" :disableSave="doesNeedUpdate" @cancel="cancel" @save="save" editable>
                <template v-slot:tooltipBody>
                    <div style="max-width: 300px;">
                        Required to use as criteria for creating property groups. Assign the contact responsible for this property to enable grouping and report downloads.
                    </div>
                </template>
                <template v-slot:display>
                    {{operationsManager}}
                </template>
                <template v-slot:edit>
                    <HbAutocomplete 
                        v-model="selected.operations_manager"
                        :items="adminsList"
                        item-text="name"
                        item-value="contact_id"
                        placeholder="Management Contact"
                    />
                </template>
            </hb-form>
            <hb-form label="Same Store Class" :active.sync="active" :disableSave="doesNeedUpdate" @cancel="cancel" @save="save" editable>
                <template v-slot:tooltipBody>
                    <div style="max-width: 300px;">
                        Required to use as criteria for property groups. Assign a rating (A, B, C) or a 0-10 scale to classify the property's overall health.
                    </div>
                </template>
                <template v-slot:display>
                    {{selected.same_store_class}}
                </template>
                <template v-slot:edit>
                    <HbSelect 
                        v-model="selected.same_store_class"
                        :items="sameStoreClass"
                        placeholder="Same Store Class"
                        item-text="value"
                        item-value="value"
                    />
                </template>
            </hb-form>
            <hb-form label="Property Build type" :active.sync="active" :disableSave="doesNeedUpdate" @cancel="cancel" @save="save" editable>
                <template v-slot:display>
                    {{propertyBuildType}}
                </template>
                <template v-slot:edit>
                    <HbSelect 
                        v-model="selected.build_type"
                        :items="propertyBuildTypeList"
                        item-text="name"
                        item-value="label"
                        placeholder="Property Build type"
                    />
                </template>
            </hb-form>
            <hb-form label = "Property Web CMS ID" :active.sync="active" :disableSave="doesNeedUpdate" @cancel="cancel" @save="save" editable>
                <template v-slot:display>
                    {{selected.web_cms_id}}
                </template>
                <template v-slot:edit>
                    <HbTextField
                        v-model="selected.web_cms_id"
                        placeholder="Property Web CMS ID"
                    >
                    </HbTextField>
                </template>
            </hb-form>
            <hb-form label = "General Ledger ID" :active.sync="active" :disableSave="doesNeedUpdate" @cancel="cancel" @save="save" editable>
                <template v-slot:display>
                    {{selected.general_ledger_id}}
                </template>
                <template v-slot:edit>
                    <HbTextField
                        v-model="selected.general_ledger_id"
                        placeholder="General Ledger ID"
                    >
                    </HbTextField>
                </template>
            </hb-form>
            <hb-form label="Entity" :active.sync="active" :disableSave="doesNeedUpdate" @cancel="cancel" @save="save" editable>
                <template v-slot:display>
                    {{selected.Entity?.name}}
                </template>
                <template v-slot:edit>
                    <HbAutocomplete 
                        v-model="selected.Entity"
                        :items="entityList"
                        item-text="name"
                        return-object
                        placeholder="Entity"
                        @keydown.enter="setEntityList($event.target.value)"
                    />
                </template>
            </hb-form>
            <hb-form label = "BankID" :active.sync="active" :disableSave="doesNeedUpdate" @cancel="cancel" @save="save" editable>
                <template v-slot:display>
                    {{selected.bank_id}}
                </template>
                <template v-slot:edit>
                    <HbTextField
                        v-model="selected.bank_id"
                        placeholder="BankID"
                    >
                    </HbTextField>
                </template>
            </hb-form>
            <hb-form label = "Tax Code" :active.sync="active" :disableSave="doesNeedUpdate" @cancel="cancel" @save="save" editable>
                <template v-slot:display>
                    {{selected.tax_code}}
                </template>
                <template v-slot:edit>
                    <HbTextField
                        v-model="selected.tax_code"
                        placeholder="E.g. SI-GSTTS-ABALZE"
                    >
                    </HbTextField>
                </template>
            </hb-form>
        </template>
    </hb-modal>
</template>

<script type="text/babel">
import { mapGetters, mapActions } from "vuex";
import VuetifyStatepicker from "../../includes/VuetifyStatepicker";
import { parsePhoneNumber } from 'libphonenumber-js';
import { notificationMixin } from "../../../mixins/notificationMixin.js";
import { countryStateCityMixin } from '../../../mixins/countryStateCityMixin';
import HbDatePicker from '../../assets/HummingbirdDatepicker';
import moment from 'moment';

export default {
    name: "AddEditProperty",
    data: () => ({
        selected: {
            name: "",
            legal_name: '',
            number: "",
            Emails: [
                {
                    id: null,
                    type: "Main",
                    email: ""
                }
            ],
            Phones: [
                {
                    id: null,
                    type: "Main",
                    phone: ""
                }
            ],
            Address: {
                address: "",
                address2: "",
                city: "",
                state: "",
                zip: "",
                region: "",
                district: ""
            },
            merchandise_delivery: "0"
        },
        notification: {
            active: false,
            type: "",
            message: ""
        },
        countryCodesList: [
            "1",
            "7",
            "20",
            "27",
            "30",
            "31",
            "32",
            "33",
            "34",
            "36",
            "39",
            "40",
            "41",
            "43",
            "44",
            "45",
            "46",
            "47",
            "48",
            "49",
            "51",
            "52",
            "53",
            "54",
            "55",
            "56",
            "57",
            "58",
            "60",
            "61",
            "62",
            "63",
            "64",
            "65",
            "66",
            "81",
            "82",
            "84",
            "86",
            "90",
            "91",
            "92",
            "93",
            "94",
            "95",
            "98",
            "211",
            "212",
            "213",
            "216",
            "218",
            "220",
            "221",
            "222",
            "223",
            "224",
            "225",
            "226",
            "227",
            "228",
            "229",
            "230",
            "231",
            "232",
            "233",
            "234",
            "235",
            "236",
            "237",
            "238",
            "239",
            "240",
            "241",
            "242",
            "243",
            "244",
            "245",
            "246",
            "247",
            "248",
            "249",
            "250",
            "251",
            "252",
            "253",
            "254",
            "255",
            "256",
            "257",
            "258",
            "260",
            "261",
            "262",
            "263",
            "264",
            "265",
            "266",
            "267",
            "268",
            "269",
            "290",
            "291",
            "297",
            "298",
            "299",
            "350",
            "351",
            "352",
            "353",
            "354",
            "355",
            "356",
            "357",
            "358",
            "359",
            "370",
            "371",
            "372",
            "373",
            "374",
            "375",
            "376",
            "377",
            "378",
            "380",
            "381",
            "382",
            "383",
            "385",
            "386",
            "387",
            "389",
            "420",
            "421",
            "423",
            "500",
            "501",
            "502",
            "503",
            "504",
            "505",
            "506",
            "507",
            "508",
            "509",
            "590",
            "591",
            "592",
            "593",
            "594",
            "595",
            "596",
            "597",
            "598",
            "599",
            "670",
            "672",
            "673",
            "674",
            "675",
            "676",
            "677",
            "678",
            "679",
            "680",
            "681",
            "682",
            "683",
            "685",
            "686",
            "687",
            "688",
            "689",
            "690",
            "691",
            "692",
            "850",
            "852",
            "853",
            "855",
            "856",
            "880",
            "886",
            "960",
            "961",
            "962",
            "963",
            "964",
            "965",
            "966",
            "967",
            "968",
            "970",
            "971",
            "972",
            "973",
            "974",
            "975",
            "976",
            "977",
            "992",
            "993",
            "994",
            "995",
            "996",
            "998"
        ],
        phoneCode: '1',
        phoneNumber: '',
        invalidPhone: false,
        active: '',
        occupancyThresholds: [],
        sameStoreClass: [
                { header: "Type" },
                { value: "A", group: "Type" },
                { value: "B", group: "Type" },
                { value: "C", group: "Type" },
                { header: "Range" },
                { value: "0", group: "Range"},
                { value: "1", group: "Range"},
                { value: "2", group: "Range" },
                { value: "3", group: "Range" },
                { value: "4", group: "Range" },
                { value: "5", group: "Range" },
                { value: "6", group: "Range" },
                { value: "7", group: "Range" },
                { value: "8", group: "Range" },
                { value: "9", group: "Range" },
                { value: "10", group: "Range" },
            ],
        doesNeedUpdate: false,
        isInvalidYear: false,
    }),
    mixins: [notificationMixin, countryStateCityMixin],
    props: ["activePropertyID", "setNotification", "value", "adminsList","suburbsList", "marketRegionsList", "entityList","performanceCategoryList", "propertyBuildTypeList"], 
    components: {
        VuetifyStatepicker,
        HbDatePicker
    },
    async created(){
        this.setCountriesListEvent();
        this.setOccupancyThresholdList();
    },
    destroyed(){
        this.resetMissingCityList()
    },
    computed: {
        ...mapGetters({
            property: "propertiesStore/property",
            properties: "propertiesStore/filtered"
        }),
        dialog: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
        performanceCategory() {
            let category = this.performanceCategoryList.find(pc => pc.label == this.selected.performance_category);
            return category && category.name;
        },
        propertyBuildType() {
            let category = this.propertyBuildTypeList.find(pb => pb.label == this.selected.build_type);
            return category && category.name;
        },
        operationsManager: {
            get () {
                let manager = this.adminsList.find(admin => admin.contact_id == this.selected.operations_manager);
                return manager && manager.name;
            },
            set(value) {
                value = value || {}
                return value.name
            }
        }
    },
    mounted() {
        if (this.$props.activePropertyID && this.property.id) {
            this.selected = JSON.parse(JSON.stringify(this.property));

            this.selected.Address.country = this.setCountryNameEvent(this.property.Address.country)
            this.selected.Address.state = this.property.Address.state || ''
            this.selected.Address.region  = this.marketRegionsList.find(mr => mr.name === this.selected.Address.region) || {};

            this.setPropertyStatesListEvent(this.selected.Address.country)
            this.setPropertyCityList(this.property.Address.city)

            if(!this.selected.Address.state){
                this.setPropertyCountryCitiesListEvent(this.selected.Address.country)
            }else{
                this.setPropertyCitiesListEvent(this.selected.Address.country, this.selected.Address.state)
            }

            

            if(this.selected.Emails && this.selected.Emails.length === 0){
                this.selected.Emails = [
                    {
                        id: null,
                        type: "Main",
                        email: ""
                    }
                ]
            }
            if(this.selected.Phones && this.selected.Phones.length && this.selected.Phones[0] && this.selected.Phones[0].id && this.selected.Phones[0].phone && this.selected.Phones[0].phone.length > 0){
                try {
                    var parseEditedPhone = parsePhoneNumber('+' + this.selected.Phones[0].phone);
                } catch(err) {
                    console.log(err);
                }

                if(!parseEditedPhone){
                    this.phoneCode = '';
                    this.phoneNumber = this.selected.Phones[0].phone;
                } else {
                    this.phoneCode = parseEditedPhone.countryCallingCode;
                    this.phoneNumber = parseEditedPhone.nationalNumber;
                }
            } else if(this.selected.Phones && this.selected.Phones.length === 0){
                this.selected.Phones = [
                    {
                        id: null,
                        type: "Main",
                        phone: this.phoneCode + this.phoneNumber
                    }
                ]
            }
        }else{
            this.selected.Address.country = this.defaultCountry
            this.setPropertyStatesListEvent(this.selected.Address.country)
            this.setPropertyCountryCitiesListEvent(this.selected.Address.country)
        }

        this.setOperationManager();
    },
    methods: {
        ...mapActions({
            addProperty: "propertiesStore/addProperty",
            editProperty: "propertiesStore/editProperty",
            setProperty: "propertiesStore/setProperty"
        }),
        setPropertyCountry(){
            this.selected.Address.state = ''
            this.selected.Address.city = ''
            this.setPropertyStatesListEvent(this.selected.Address.country)
            this.setPropertyCountryCitiesListEvent(this.selected.Address.country)
        },
        setPropertyState(){
            this.selected.Address.city = ''
            this.setPropertyCitiesListEvent(this.selected.Address.country, this.selected.Address.state)
        },
        setPropertyCityList(value, toValidate = false){
            if(!value) return
            if(toValidate){
                const isSpecialCharacter = this.validateCityName(value)
                const cityValidation = this.errors.items && this.errors.items.indexOf('property.city')
                this.errors.items.splice(cityValidation);
                if(isSpecialCharacter){
                  return this.errors.add({
                            field: 'property.city',
                            msg: 'Please add a valid city name.'
                        }) 
                }
            }
            const isCityExists = this.propertyCityListMixin.some((city) => city.toLowerCase() === value.toLowerCase())
            if(isCityExists) return

            this.propertyCityListMixin.push(value)
            const country = this.countriesListMixinRaw.find(item => item.name === this.selected.Address.country);
            this.addMissingCity({
                country_code: country.isoCode,
                state_code: this.selected.Address.state,
                city: value
            })
        },
        async save() {
            let status = await this.$validator.validateAll("default");
            if (!status) {
                return;
            }
            else if(this.invalidPhone) {
                this.showMessageNotification({ id: this.$options.name, type: "error", description: 'Please Enter a Valid Phone Number' });
                return;
            }
            
            let data = this.selected;

            let yearOpened = moment(data.year_opened);
            let yearClosed = moment(data.year_closed);

            if(yearOpened.isAfter(yearClosed)) {
                this.isInvalidYear = true;
                this.showMessageNotification({ description: 'The Year Opened cannot be greater than the Year Closed' });
                return;
            }

            data.Suburbs = this.formatData(data.Suburbs, this.suburbsList);
            data.MarketRegions = this.formatData(data.MarketRegions, this.marketRegionsList);
            data.Address.region = data.Address.region?.name;

            if(this.phoneCode && this.phoneCode.length && this.phoneNumber.length){
                let parsedPhoneNumber;

                parsedPhoneNumber = parsePhoneNumber('+' + this.phoneCode + this.phoneNumber);

                if(parsedPhoneNumber && parsedPhoneNumber.isValid()){
                    data.Phones = [
                        {
                            id: this.selected.Phones[0].id && this.selected.Phones[0].id.length ? this.selected.Phones[0].id : null,
                            type: "Main",
                            phone: parsedPhoneNumber.countryCallingCode + parsedPhoneNumber.nationalNumber
                        }
                    ]
                }
                else {
                    this.showMessageNotification({ id: this.$options.name, type: "error", description: 'Please Enter a Valid Phone Number' });
                    return;
                }
            } else {
                data.Phones = [
                    {
                        id: this.selected.Phones[0] && this.selected.Phones[0].id && this.selected.Phones[0].id.length ? this.selected.Phones[0].id : null,
                        type: "Main",
                        phone: ""
                    }
                ]
            }

            if(this.selected.Emails[0] && this.selected.Emails[0].email && this.selected.Emails[0].email.length){
                data.Emails = [
                    {
                        id: this.selected.Emails[0].id && this.selected.Emails[0].id.length ? this.selected.Emails[0].id : null,
                        type: "Main",
                        email: this.selected.Emails[0].email
                    }
                ]
            } else {
                data.Emails = [
                    {
                        id: this.selected.Emails[0] && this.selected.Emails[0].id && this.selected.Emails[0].id.length ? this.selected.Emails[0].id : null,
                        type: "Main",
                        email: ""
                    }
                ]
            }

            this.doesNeedUpdate = true;
            if (this.selected.id) {
                await this.editProperty(data).then(
                    response => {
                        let properties = JSON.parse(JSON.stringify(this.properties));
                        let updatedProperty = properties.find(p => p.id == data.id);
                        updatedProperty = this.formatPropertyData(updatedProperty);
                        this.setProperty(updatedProperty);
                        
                        this.selected.Suburbs = updatedProperty.Suburbs;
                        this.selected.MarketRegions = updatedProperty.MarketRegions;
                        this.selected.Entity = updatedProperty.Entity;
                        this.selected.Address.region = this.marketRegionsList.find(mr => mr.name === this.selected.Address.region) || {};

                        this.$emit("refresh-suburbs");
                        this.$emit("refresh-market-regions");
                        this.$emit("refresh-entities");

                        this.showMessageNotification({ id: this.$options.name, type: "success", description: 'The property "' + data.name + '" has been edited.' });
                    },
                    err => {
                        this.cancel();
                        this.showMessageNotification({ id: this.$options.name, type: "error", list: [{ msg: err }] });
                    }
                );
            }
            this.active = 'saved';
            this.doesNeedUpdate = false;
        },
        closeWindow() {
            this.selected = {
                name: "",
                number: "",
                Emails: [
                    {
                        id: null,
                        type: "Main",
                        email: ""
                    }
                ],
                Phones: [
                    {
                        id: null,
                        type: "Main",
                        phone: ""
                    }
                ],
                Address: {
                    address: "",
                    address2: "",
                    city: "",
                    state: "",
                    country: "",
                    zip: "",
                    region: "",
                    district: ""
                }
            };
            this.$nextTick(() => {
                this.$emit("close");
            });
        },
        checkValidPhone(code, number){
            let phone = '+' + code + number;
            try {
                let parsedPhoneNumber = parsePhoneNumber(phone);
                this.invalidPhone = !parsedPhoneNumber.isValid();
            }
            catch(err) {
                this.invalidPhone = true;
            }
        },
        setOccupancyThresholdList() {
           for(let i=0; i<101; i++) {
               this.occupancyThresholds.push(i);
           }
        },
        setOperationManager() {
            this.operationsManager = this.adminsList.find(admin => admin.contact_id === this.selected.operations_manager) || {};
        },
        cancel() {
            let propertyData = JSON.parse(JSON.stringify(this.property));
            this.selected = propertyData;
            this.selected.Address.region = this.marketRegionsList.find(mr => mr.name === this.selected.Address.region) || {}

            if(this.selected.Emails && this.selected.Emails.length === 0){
                this.selected.Emails = [
                    {
                        id: null,
                        type: "Main",
                        email: ""
                    }
                ]
            }
            if(this.selected.Phones && this.selected.Phones.length && this.selected.Phones[0] && this.selected.Phones[0].id && this.selected.Phones[0].phone && this.selected.Phones[0].phone.length > 0){
                try {
                    var parseEditedPhone = parsePhoneNumber('+' + this.selected.Phones[0].phone);
                } catch(err) {
                    console.log(err);
                }

                if(!parseEditedPhone){
                    this.phoneCode = '';
                    this.phoneNumber = this.selected.Phones[0].phone;
                } else {
                    this.phoneCode = parseEditedPhone.countryCallingCode;
                    this.phoneNumber = parseEditedPhone.nationalNumber;
                }
            } else if(this.selected.Phones && this.selected.Phones.length === 0){
                this.phoneCode = '1';
                this.phoneNumber = '';
                this.selected.Phones = [
                    {
                        id: null,
                        type: "Main",
                        phone: this.phoneCode + this.phoneNumber
                    }
                ]
            }

            this.isInvalidYear = false;
        },
        setSuburbList(value){
            if(!value) return
            
            const isSuburbExists = this.suburbsList.some((suburb) => suburb?.name?.toLowerCase() === value.toLowerCase())
            if(isSuburbExists) return
            this.suburbsList.push({name: value, id: null});
        },
        setEntityList(value){
            if(!value) return
            
            const isEntityExists = this.entityList.some((entity) => entity?.name?.toLowerCase() === value.toLowerCase())
            if(isEntityExists) return
            this.entityList.push({name: value, id: null});
        },
        formatData(data, dataList){
            if(!data) return
            
            let formatted = [];
            for(let i=0; i<data.length; i++) {
                let unFormatted = data[i];
                
                if(typeof unFormatted == 'string') {
                    let data = dataList.find(d => d.name == unFormatted)
                    if(data) formatted.push(data);
                    else formatted.push({name: unFormatted, id: null})
                }
                else {
                formatted.push(unFormatted)
                }
            }
            return formatted;
        },
        formatPropertyData(data) {
            if(!data) return
            return {
                        id: data.id,
                        number: data.number,
                        name: data.name,
                        legal_name: data.legal_name,
                        Emails: data.Emails,
                        Phones: data.Phones,
                        Address: {
                            address: data.Address.address,
                            address2: data.Address.address2,
                            city: data.Address.city,
                            state: data.Address.state,
                            country: data.Address.country,
                            zip: data.Address.zip,
                            region: data.Address.region,
                            district: data.Address.district,
                            lat: data.Address.lat,
                            lng: data.Address.lng,
                            // Suburb: data.Address.Suburb,
                        },
                        Suburbs: data.Suburbs,
                        MarketRegions: data.MarketRegions,
                        Entity: data.Entity,
                        merchandise_delivery: data.merchandise_delivery,
                        google_analytics_id: data.google_analytics_id,
                        landing_page: data.landing_page,
                        tax_number: data.tax_number,
                        year_opened: data.year_opened,
                        year_closed: data.year_closed,
                        occupancy_threshold: data.occupancy_threshold,
                        performance_category: data.performance_category,
                        operations_manager: data.operations_manager,
                        same_store_class: data.same_store_class,
                        build_type: data.build_type,
                        web_cms_id: data.web_cms_id,
                        general_ledger_id: data.general_ledger_id,
                        bank_id: data.bank_id,
                        tax_code: data.tax_code
                    };
        },
        setMarketRegionsList(value){
            if(!value) return
            
            const isMarketRegionExists = this.marketRegionsList.some((mr) => mr?.name?.toLowerCase() === value.trim().toLowerCase())
            if(isMarketRegionExists) return
            this.marketRegionsList.push({name: value, id: null});
        },
    }
};
</script>

<style lang="scss" scoped>
 .margin-top-21{
    margin-top: 21px !important
 }
</style>